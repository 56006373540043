import React, {useLayoutEffect, useState, FC} from "react";
import axios from "axios";
import moment from "moment";

import Filters from "../../components/filters";
import {CircularProgress} from "@material-ui/core";
import Root from "../../components/root";
import {TData, TFilters} from "../../types";

import s from "../../App.module.scss";
import Header from "../../components/header";

const defaultActiveDate = moment().subtract(30, 'days').format('YYYY-MM-DD');

type Props = {
  clientName: any
}

const Tickets: FC<Props> = ({clientName}) => {

  const [data, setData] = useState<TData[] | []>([]);
  const [filters, setFilters] = useState<TFilters>({
    status: 'any',
    fromDate: defaultActiveDate
  })
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useLayoutEffect(() => {
    setLoading(true);
    let params: any = {
      fromDate: filters.fromDate
    };
    if (filters.status !== 'any') {
      params.status = filters.status;
    }

    axios({
      method: 'get',
      url: `/rpc/tickets`,
      params: params
    })
      .then((res) => {
        const {data} = res;
        setData(data);
        setLoading(false);
      }).catch(() => {
      setError('Something went wrong. Please try again later.')
      setLoading(false);
    })
  }, [filters.status, filters.fromDate]);

  const downloadData = () => {
    let params: any = {
      fromDate: filters.fromDate
    };
    if (filters.status !== 'any') {
      params.status = filters.status;
    }
    axios({
      method: 'get',
      url: `/rpc/tickets.csv`,
      params: params,
      responseType: 'blob'
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${moment().format('DD/MM/YYYY HH:mm')}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
  }

  return (
    <>
      <Header client={clientName}/>
      <div className={s.main}>
        <div className={s.title}>
          Last tickets
        </div>
        <Filters filters={filters} setFilters={setFilters} downloadData={downloadData}/>
        <div className={s.rootWrap}>
          {error ?
            <div className={s.error}>
              {error}
            </div>
            :
            <>
              {loading ?
                <div className={s.loader}>
                  <CircularProgress/>
                </div> :
                <Root data={data}/>}
            </>
          }
        </div>
      </div>
    </>

  )
}

export default Tickets;
