import moment from "moment";

export const dateFilters = [
  {
    value: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    display_name: 'Last 7 days'
  },
  {
    value: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    display_name: 'Last 30 days'
  },
  {
    value: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    display_name: 'Last 6 months'
  },
  {
    value: moment().subtract(12, 'month').format('YYYY-MM-DD'),
    display_name: 'Last 12 months'
  }
]

export const statusFilters = [
  {
    value: 'any',
    display_name: 'Any status'
  },
  {
    value: 'closed',
    display_name: 'closed'
  },
  {
    value: 'active',
    display_name: 'active'
  },
]
