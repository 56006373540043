import React, {FC, useLayoutEffect, useState} from "react";
import moment from "moment";

import {TMessage} from "../../../types";

//logo
import Lank from '../../../assets/images/lank.svg';
import Webex from '../../../assets/images/webex.svg';

import s from './Message.module.scss';
import {Button} from "@material-ui/core";

type Props = {
  item: TMessage
}

const trimByWorld = (text: string, limit = 400) => {
  text = text.trim();
  if (text.length <= limit) return text;
  text = text.slice(0, limit);
  const lastSpace = text.lastIndexOf(" ");
  if (lastSpace > 0) {
    text = text.substr(0, lastSpace);
  }
  return text + "...";
}

const Message: FC<Props> = ({item}) => {

  const [show, setShow] = useState<boolean>(false);
  const [messageArray, setMessageArray] = useState<string[]>([]);

  useLayoutEffect(() => {
    const message = item.body.length > 400 ? trimByWorld(item.body) : item.body;
    const messageArray = message.split('\n');
    setMessageArray(messageArray)
    if (item.body.length > 400) {
      setShow(true)
    }
  }, [item.body])

  const showMore = () => {
    const messageArray = item.body.trim().split('\n');
    setMessageArray(messageArray);
    setShow(false);
  }

  const OutMessage = () => (
    <div className={s.wrapper}>
      <div className={s.header} style={{justifyContent: 'flex-end'}}>
        <div className={s.from} style={{marginRight: '15px', textAlign: 'right'}}>
          <div className={s.name}>{item.from}</div>
          <div className={s.fromTo}>To {item.to}</div>
        </div>
        <div className={s.logo} style={{backgroundColor: '#FFFFFF'}}><img src={Lank} alt="logo"/></div>
      </div>
      <div className={s.body}
           style={{backgroundColor: "#F2F9FF", borderRadius: '15px 0px 15px 15px', borderColor: '#A5D5FF'}}>
        <div className={s.titleBody}>
          {item.subject}
        </div>
        <div className={s.messageBody}>
          {messageArray.map((el, index) => <><span key={`${index}fromSupport`}>{el}</span><br/></>)}
        </div>
        {show &&
        <div className={s.wrapButton}><Button onClick={showMore} className={s.button}>Show more ...</Button></div>}
      </div>
      <div className={s.footer}>
        <div>{moment(item.created).format('DD MMM YYYY hh:mm')}</div>
      </div>
    </div>
  )

  const EnterMessage = () => (
    <div className={s.wrapper}>
      <div className={s.header} style={{justifyContent: 'flex-start'}}>
        <div className={s.logo} style={{backgroundColor: '#3B3B3B'}}>
          <img src={Webex} style={{marginLeft: '5px'}} alt="logo"/>
        </div>
        <div className={s.from} style={{marginLeft: '15px', textAlign: 'left'}}>
          <div className={s.name}>{item.from}</div>
          <div className={s.fromTo}>To: {item.to}</div>
        </div>
      </div>
      <div className={s.body}
           style={{backgroundColor: '#FFFFFF', borderRadius: '0px 15px 15px 15px', borderColor: '#E0E0E0'}}>
        <div className={s.titleBody}>{item.subject}</div>
        <div className={s.messageBody}>
          {messageArray.map((el, index) => <><span key={`${index}-!fromSupport`}>{el}</span><br/></>)}
        </div>
        {show &&
        <div className={s.wrapButton}><Button onClick={showMore} className={s.button}>Show more ...</Button></div>}
      </div>
      <div className={s.footer}>
        <div>{moment(item.created).format('DD MMM YYYY hh:mm')}</div>
      </div>
    </div>
  )

  return (
    <>
      {item.fromSupport ? <OutMessage/> : <EnterMessage/>}
    </>
  )
}

export default Message;
