import React, {FC} from "react";
import {Button, MenuItem, TextField} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

import {TFilters} from "../../types";

import {statusFilters, dateFilters} from "./helpers";
import s from './Filters.module.scss';

type Props = {
  filters: TFilters
  setFilters: (args: TFilters) => void;
  downloadData: () => void;
}

const Filters: FC<Props> = ({filters, setFilters, downloadData}) => {

  const changeStatus = (e: any) => {
    setFilters({
      ...filters,
      status: e.target.value
    })
  }

  const changeDate = (e: any) => {
    setFilters({
      ...filters,
      fromDate: e.target.value
    })
  }

  return (
    <div className={s.root}>
      <TextField
        select
        value={filters.status}
        variant="outlined"
        onChange={changeStatus}
        label="Status"
        className={s.selects}
        inputProps={{
          className: s.input
        }}
      >
        {statusFilters.map((el, index) => (
          <MenuItem value={el.value} key={index}>
            {el.display_name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        value={filters.fromDate}
        variant="outlined"
        onChange={changeDate}
        label="Created in"
        className={s.selects}
        inputProps={{
          className: s.input
        }}
      >
        {dateFilters.map((el, index) => (
          <MenuItem value={el.value} key={index}>
            {el.display_name}
          </MenuItem>
        ))}
      </TextField>
      <Button
        onClick={downloadData}
        className={s.button}
        startIcon={<GetAppIcon/>}
      >
        Download CSV
      </Button>
    </div>
  )
}

export default Filters;
