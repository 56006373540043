import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { TData } from "../../types";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { TABLE_HEAD } from "./helper";

import s from './Root.module.scss'

type Props = {
  data: TData[]
}

const getClx = (status: string) => {
  switch (status) {
    case "active":
      return s.active;
    case "closed":
      return s.closed;
    default:
      return s.default;
  }
}

const getBreakText = (text: string) => {
  const start = text.split(' ');
  return (
    <div>
      {start[0]}
      <br/>
      {start.length > 1 ?
        start[1] : ''
      }
    </div>
  )
}

const Root: FC<Props> = ({data}) => {

  let history = useHistory()

  const onClickRow = (id: number) => {
    history.push(`/tickets/${id}`)
  }

  return (
    <div>
      <Table
        stickyHeader
        classes={{
          root: s.table
        }}>
        <TableHead>
          <TableRow>
            {TABLE_HEAD.map((el, index) => (
              <TableCell key={index} width={el.width} className={s.thead}>
                <div className={index === 0 ? s.theadNumber : ''}>
                  {el.name === 'Origination Region' ? (
                    getBreakText(el.name)
                  ) : el.name}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length ?
            <>
              {data.map((el, index) => (
                <TableRow key={`${el.number} ${index}`} onClick={() => onClickRow(el.id)} className={s.row}>
                  <TableCell className={s.tbody}>
                    <div className={s.number}>
                      {el.number}
                    </div>
                  </TableCell>
                  <TableCell className={s.tbody}>
                    <div className={s.title}>
                      {el.partnerNumber}
                    </div>
                  </TableCell>
                  <TableCell className={s.tbody}>
                    <div className={s.title}>
                      {el.title}
                    </div>
                  </TableCell>
                  <TableCell className={s.tbody}>
                    <div className={s.country}>
                      {el.originationRegion}
                    </div>
                  </TableCell>
                  <TableCell className={s.tbody}>
                    <div className={s.codes}>
                      {el.destinationCodes}
                    </div>
                  </TableCell>
                  <TableCell className={s.tbody}>
                    <div className={`${s.status} ${getClx(el.status)}`}>
                      {el.status}
                    </div>
                  </TableCell>
                  <TableCell className={s.tbody}>
                    <div className={s.date}>
                      {moment(el.updated).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                  </TableCell>
                  <TableCell className={s.tbody}>
                    <div className={s.date}>
                      {moment(el.created).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </>
            :
            <TableRow>
              <TableCell colSpan={7} align="center" style={{borderBottomWidth: '0'}}>
                <div className={s.empty}>
                  There are no records for this period
                </div>
              </TableCell>
            </TableRow>
          }

        </TableBody>
      </Table>
    </div>
  )
}

export default Root;
