import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiSelect: {
      root: {
        '&:focus': {
          backgroundColor: "#fff",
        },
        '&.selectCustomInput': {
          backgroundColor: '#1B4695',
          borderRadius: '30px',
          padding: '7px 36px 5px 24px',
          paddingRight: '36px !important',
          color: '#fff',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '19px',
          '&:before': {
            borderBottom: '0px solid black',
          },
          '&:focus': {
            backgroundColor: '#1B4695',
            borderRadius: '30px',
            padding: '7px 36px 5px 24px',
            paddingRight: '36px !important',
            color: '#fff',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
          },
        },
      },
      outlined: {
        '&:focus': {
          backgroundColor: "#fff",
        },
      }
    },
    MuiSvgIcon: {
      root: {
        '&.selectCustomIcon': {
          color: '#fff',
          '&.MuiSelect-iconOpen': {
            marginRight: '0',
          }
        },
        '&.selectCustomIconSecondary': {
          color: '#0176FE',
          '&.MuiSelect-iconOpen': {
            marginRight: '0',
          }
        }
      },
      fontSizeLarge: {
        fontSize: '16px',
      },
      fontSizeSmall: {
        fontSize: '16px',
      },
    },
  }
})

export default theme;
