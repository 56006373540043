import React from "react";
import LaunchIcon from '@material-ui/icons/Launch';

import s from './Sidebar.module.scss'

const Sidebar = () => {
  return (
    <div className={s.root}>
      <div className={s.logo}/>
      <div>
        <div className={s.listTitle}>
          RESOURCES
        </div>
        <div className={`${s.link} ${s.active}`}>
          <a href="/">
            <div className={s.flex}>
              Requests Processing Centre
            </div>
          </a>
        </div>
        <div className={s.link}>
          <a href="https://fms.lancktele.com">
            <div className={s.flex}>
              FMS Control Centre
              <LaunchIcon/>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Sidebar;
