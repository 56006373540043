import React, {FC, useLayoutEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import {CircularProgress} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';

import Header from "../../components/header";
import {TTicket, TMessage} from "../../types";

import s from "./Ticket.module.scss";
import moment from "moment";
import Message from "./message";

type Props = {
  clientName: any
}

const getClx = (status: string | undefined) => {
  switch (status) {
    case "active":
      return s.active;
    case "closed":
      return s.closed;
    default:
      return s.default;
  }
}

const Ticket: FC<Props> = ({clientName}) => {

  // @ts-ignore
  let {id} = useParams();
  let history = useHistory()

  const [ticket, setTicket] = useState<TTicket>();
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useLayoutEffect(() => {
    Promise.all([
      axios.get(`/rpc/tickets/${id}`),
      axios.get(`/rpc/tickets/${id}/messages`)
    ])
      .then((res) => {
        const [ticket, message] = res;
        setTicket(ticket.data);
        setMessages(message.data)
      }).catch(() => {
      console.error('Something went wrong. Please try again later.')
      history.push('/tickets')
    }).finally(() => {
      setTimeout(() => setLoading(false), 500);
    })
  }, [id, history])


  const goBack = () => {
    history.push('/tickets')
  }

  const getFlex = (el: boolean) => el ? 'flex-end' : 'flex-start';

  return (
    <>
      <Header client={clientName} name={ticket?.number}/>
      <div className={s.main}>
        {loading ? <div className={s.wrapperLoader}><CircularProgress/></div> :
          <>
            <div className={s.header}>
              <ArrowBackIcon className={s.iconBack} onClick={goBack}/>
              <div>
                Ticket {ticket?.number}
              </div>
              <span className={`${s.status} ${getClx(ticket?.status)}`}>
              {ticket?.status}
            </span>
            </div>
            <div className={s.body}>
              <div className={s.info}>
                <div className={s.subtitle}>Ticket details</div>

                <div className={s.infoRow}>
                  <div className={s.infoRowTitle}>PARTNER‘S TICKET ID</div>
                  <div className={s.infoRowContent}>
                    {ticket?.partnerNumber || '-'}
                  </div>
                </div>

                {ticket?.updated &&
                <div className={s.infoRow}>
                  <div className={s.infoRowTitle}>Updated at</div>
                  <div className={s.infoRowContent}>
                    {moment(ticket.updated).fromNow()}
                  </div>
                </div>
                }
                {ticket?.created &&
                <div className={s.infoRow}>
                  <div className={s.infoRowTitle}>Created at</div>
                  <div className={s.infoRowContent}>
                    {moment(ticket.created).format('DD/MM/YYYY hh:mm:ss')}
                  </div>
                </div>
                }
                {ticket?.originationRegion &&
                <div className={s.infoRow}>
                  <div className={s.infoRowTitle}>ORIGIN REGION</div>
                  <div className={s.infoRowContent}>
                    {ticket.originationRegion}
                  </div>
                </div>
                }
                {ticket?.title &&
                <div className={s.infoRow}>
                  <div className={s.infoRowTitle}>Description</div>
                  <div className={s.infoRowContent}>
                    {ticket.title}
                  </div>
                </div>
                }
                {ticket?.destinationCodes &&
                <div className={s.infoRow}>
                  <div className={s.infoRowTitle}>Destination Codes</div>
                  <div className={s.infoRowContent}>
                    {ticket.destinationCodes}
                  </div>
                </div>
                }
              </div>
              <div className={s.messages}>
                <div className={s.subtitle}>Conversation</div>
                {messages && messages.length > 0 ?
                  messages.map((el, index) => (
                    <div key={index} className={s.rowMessage} style={{justifyContent: getFlex(el.fromSupport)}}>
                      <Message item={el}/>
                    </div>
                  )) :
                  <div className={s.emptyMessage}>
                    <div className={s.emptyContent}>
                      <SpeakerNotesOffIcon/>
                      <div>No messages</div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default Ticket;
