import React, {useLayoutEffect, useState} from 'react';
import {
  Switch,
  Route,
  Redirect,
  HashRouter
} from "react-router-dom";
import axios from "axios";
import {ThemeProvider} from '@material-ui/core/styles';

import theme from "./utils/theme";
import Sidebar from "./components/sidebar";
import Tickets from "./view/tickets";
import Ticket from "./view/ticket";

import s from './App.module.scss';

const App = () => {
  const [clientName, setClientName] = useState<string | null>(null);

  useLayoutEffect(() => {
    axios({
      method: 'get',
      url: `/rpc/account`
    }).then((res) => {
      setClientName(res.data.client);
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <div className={s.root}>
          <Sidebar/>
          <div className={s.content}>
            <Switch>
              <Route
                exact
                path="/tickets"
                render={() => <Tickets clientName={clientName}/>}
              />
              <Route
                exact
                path="/tickets/:id"
                render={() => <Ticket clientName={clientName}/>}
              />
              <Redirect from='*' to='/tickets'/>
            </Switch>
          </div>
        </div>
      </HashRouter>
    </ThemeProvider>
  )
}

export default App;
