export const TABLE_HEAD = [
  {
    name: 'LANCK TICKET ID',
  },
  {
    name: 'PARTNER‘S TICKET ID',
  },
  {
    name: 'Details',
    width: '30%',
  },
  {
    name: "ORIGIN REGION",
    width: 40,
  },
  {
    name: 'Destination Codes',
    width: '13%',
  },
  {
    name: 'Status',
    width: 60,
  },
  {
    name: 'Updated at',
    width: '13%',
  },
  {
    name: 'Created at',
    width: '13%',
  },
]
