import React, {FC} from "react";

import {MenuItem, Select} from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import s from './Header.module.scss'

type Props = {
  client: string,
  name?: string
}

const Header: FC<Props> = ({client, name}) => {
  return (
    <div className={s.root}>
      <div className={s.title}>
        {name ?
          <>Voice / <a href={'/tickets'}>All Tickets</a> / <br/><span>{name}</span></> :
          <>Voice / <br/><span>Requests Processing Centre</span></>
        }
      </div>
      <div className={s.wrapSelect}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={client}
          className={s.select}
          disableUnderline={true}
          classes={{
            select: 'customSelect'
          }}
          inputProps={{
            classes: {
              root: 'selectCustomInput',
              icon: 'selectCustomIcon',
            },
          }}
        >
          <MenuItem value={client}>
            {client}
          </MenuItem>
        </Select>
        <div className={s.userIcon}>
          <AccountCircleIcon/>
        </div>
      </div>
    </div>
  )
}

export default Header
